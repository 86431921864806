



































































































































































































































































































































































































































































































































import Vue from "vue";
import Layout from "@/router/layouts/main.vue";
import ActivePlaylist from "@/components/venue-detail/ActivePlaylist.vue";
import OpeningHours from "@/components/venue-detail/OpeningHours.vue";
import GeneralInfo from "@/components/venue-detail/GeneralInfo.vue";
import ExtendedInfo from "@/components/venue-detail/ExtendedInfo.vue";
import GoogleMap from "@/components/venue-detail/GoogleMap.vue";
import {mapActions, mapGetters} from "vuex";
import router from "@/router";
import _ from 'lodash';
import {SubscriptionProductsData} from "@/types";
import { errorMessage, successMessage } from "@/utils/messages-handlers";
import i18n from "@/i18n/i18n";
import DatePicker from "@/mixins/DatePicker";
import { ToggleButton } from "vue-js-toggle-button";
import vSelect from "vue-select";

Object.defineProperty(Vue.prototype, '$_', {value: _});

export default Vue.extend({
  name: "venue-detail" as string,

  components: {
    Layout,
    "active-playlist": ActivePlaylist,
    "opening-hours": OpeningHours,
    "general-info": GeneralInfo,
    "extended-info": ExtendedInfo,
    "google-map": GoogleMap,
    ToggleButton,
    vSelect,
  },

  async created(): Promise<void> {
  },

  async mounted(): Promise<void> {

  },

  data() {
    return {
      updatedVenueOpeningHours: {},
      updatedVenueGeneralInfo: {},
      updatedVenueExtendedInfo: {},
      venuePhoto: "",
      lang: DatePicker,
      i18: i18n,
      checkboxValue: false,
      checkboxValue2: false,
      venue:{
        name: 'Example venue',
        legal_entity_name: 'VEnue',
        description: 'Example description',
        latitude: 39,
        longitude: 40,
        address1: 'Kosicka 10',
        address2: '05504',
        city: 'Kosice',
        contact: '',
        contact_tel: '0917090555',
        contact_email: 'example@example.com',
        state: 'Slovakia',
        venue_type_id: 1,
        venue_owner_id: 5,
        timezone_id: 2,
        country_id:10,
      }
    }
  },

  computed: {
    ...mapGetters("venueSubscriptionModule", {
      subscriptionProducts: "GET_ALL_SUBSCRIPTION_PRODUCTS",
      selectedVenueSubscription: "GET_SELECTED_VENUE_SUBSCRIPTION"
    }),
    ...mapGetters("venueAppFeaturesModule", {
      selectedVenue: "GET_SELECTED_VENUE",
      // appFeatures: "GET_APP_FEATURES",
      // venueTypeFeatures: "GET_VENUE_TYPE_FEATURES",
      finalFeatures: "GET_VENUE_FINAL_FEATURES",
      foundSongs: "GET_FOUND_SONGS",
      foundArtists: "GET_FOUND_ARTISTS",
      censoredSongs: "GET_CENSORED_SONGS",
      censoredArtists: "GET_CENSORED_ARTISTS"
    }),
    isSubscriptionProductPremium(): boolean {
      if (!this.subscriptionProducts.length || !this.selectedVenueSubscription.stripeProductWithPriceId) return;
      const product: SubscriptionProductsData = this.subscriptionProducts.find(p => p.id === this.selectedVenueSubscription.stripeProductWithPriceId);
      return product.songorooPackageType === 2;
    },
    FilterCampaign(){
      let filterValues = [];
      filterValues.push({ label:i18n.t("All surveys"), value:0})
      this.venueQuestionary.map(d => {
        if(d.main){
          filterValues.push({ label: i18n.t("Default survey"), value:d.id})
        } else {
          filterValues.push({ label:d.name, value:d.id})
        }
      })
      return filterValues
    },
    locale(){
      return i18n.locale
    }
  },

  methods: {
    ...mapActions("venueModule", {
    }),
    ...mapActions("globalModule", {
      setImage: "FETCH_ENTITY_IMAGE"
    }),
    ...mapActions("venueSubscriptionModule", {
      setSubscriptionProducts: "FETCH_SUBSCRIPTION_PRODUCTS",
      setVenueSubscription: "FETCH_VENUE_SUBSCRIPTION"
    }),
    ...mapActions("venueQuestionaryModule", {
      setVenueQuestionary: "FETCH_VENUE_QUESTIONARY"
    }),
    setOpeningHours(value: object): void {
      this.updatedVenueOpeningHours = value;
    },
    setVenueGeneralData(value: object): void {
      this.updatedVenueGeneralInfo = value;
    },
    setVenueExtendedData(value: object): void {
      this.updatedVenueExtendedInfo = value;
    },
    setVenuePhoto(value: string): void {
      this.venuePhoto = value;
    },
    async setRatings() {
      let active;
      if(this.isSubscriptionProductPremium){
        await this.setVenueDetailRating({
        });
      } else {
        active = this.venueQuestionary.filter(x => x.main)
        await this.setVenueDetailRating({ campaing: active[0].id });
      }
      this.filterCampaignRating = {label: this.isSubscriptionProductPremium ? i18n.t('All surveys') :  i18n.t('Default survey')};
      this.userId = this.venueRatings.ratings[0].user_id;
      this.questionId = this.venueQuestionary[0].id;
      this.questionaryMessageId = this.venueRatings.ratings[0].message_id;
      await this.setVenueDetailAnswers({user_id:this.userId,question_id:active[0].id});
      this.singleReview = this.venueRatings.ratings[0];
      this.setCampaignTag(this.filterCampaignRating.label)
      this.fetchUsersImages();
    },
    async updateVenue(): Promise<void> {
      if(!this.updatedVenueGeneralInfo.name){
        errorMessage('Venue name cannot be empty', 5000);
        return;
      }

      if(this.updatedVenueGeneralInfo === false) return;
      const venuePayload: object = {
        ...this.updatedVenueGeneralInfo,
        ...this.updatedVenueExtendedInfo
      }
      if (!!this.venuePhoto) {
        venuePayload["picture"] = this.venuePhoto;
      }

      await this.updateVenueData(venuePayload)
      await this.updateVenueOpeningHours(this.updatedVenueOpeningHours);
      if(this.venue.image_last_updated){
        await this.setImage({id: router.currentRoute.params.id, imageType: "clientzone_venue"});
      }
      this.edit = false
    },
    goToVenueAppFeaturesDetail(venueId: number): void {
      this.$router.push({
        path: '/app-features-setup/' + venueId,
        params: { id: venueId }
      })
    },
    pickReview(user_id,message_id,index, questionary_id) {
      this.questionaryMessageId = message_id;
      this.userId = user_id;
      this.singleReviewIndex = index;
      this.setVenueDetailAnswers({user_id:user_id, question_id: questionary_id})
      this.singleReview = this.venueRatings.ratings[index]
    },
    async AddReply() {
      const payload ={
        message: this.newReply,
        venue_questionary_message_id: this.questionaryMessageId
      }
      if(!this.newReply) return;
      await this.addReplyMessage(payload);
      successMessage('Your response was sent.', 3000);
      this.singleReview = this.venueRatings.ratings[this.singleReviewIndex];
      this.newReply = '';
    },
    timeStampConvert(createdAt){
      const timestamp = Number(createdAt) * 1000;
      const date = new Date(timestamp);

      return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
    },
    fetchUsersImages(): void {
      for (let i = 0; i < this.venueRatings.ratings.length; i++) {
        const user  = this.venueRatings.ratings[i];

        if (!!user.image_last_updated) {
          this.setImage({id: user.user_id, imageType: "user", multi: true});
        }
      }
    },
    insertVenueImage(value: string): string {
      if (!value) return "";
      return `data:image/jpeg;charset=utf-8;base64, ${value}`
    },
    async fetchRatings(value){
      await this.setVenueDetailRating({
        rating: this.filterStarRating ? this.filterStarRating : null,
        campaing: value?.value
      });

      if(!this.venueRatings.ratings.length){
        return this.singleReview = null
      }

      this.userId = this.venueRatings.ratings[0].user_id;
      this.questionId = value.value;
      this.questionaryMessageId = this.venueRatings.ratings[0].message_id;
      await this.setVenueDetailAnswers({user_id:this.userId,question_id:this.questionId});
      this.singleReview = this.venueRatings.ratings[0];
    }
  },

  watch: {
    companyId: {
      handler(newValue: number, oldValue: number): void {
        if (newValue != oldValue) {
          this.$router.push("/venues");
        }
      }
    },
    locale: async function (newLocale, oldLocale){
      await this.setRatings()
    },
    filterStarRating: {
      async handler(value) {
        await this.setVenueDetailRating({
          rating: value ? value : 0,
          campaing: this.filterCampaignRating.value ? this.filterCampaignRating.value : null
        });

        if(!this.venueRatings.ratings.length){
          return this.singleReview = null
        }

        this.userId = await this.venueRatings.ratings[0].user_id;
        this.questionaryMessageId = await this.venueRatings.ratings[0].message_id;
        await this.setVenueDetailAnswers({user_id:this.userId});
        this.singleReview = await this.venueRatings.ratings[0];
      }
    },
    filterCampaignRating: {
      async handler(value) {
        await this.fetchRatings(value)
      }
    },
    async $route(to, from): Promise<void> {
      if (to !== from) {
        await this.setVenue();
        if (!!this.venue.image_last_updated) {
          await this.setImage({id: router.currentRoute.params.id, imageType: "clientzone_venue"});
        }
        await this.setVenueSubscription(router.currentRoute.params.id);
      }
    }
  },

  beforeDestroy() {
    this.singleReview = null;
  }
});
